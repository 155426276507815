import React, { useEffect, useContext } from 'react';
import { MainContext } from '../../contexts/MainContext';
import { motion } from 'framer-motion';

import { FaPencilRuler } from 'react-icons/fa';
import { BsFillFileEarmarkRichtextFill } from 'react-icons/bs';
import { AiFillMessage } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';

import { animatedImages } from '../../constants';

import { Typewriter } from '../../components';
import LinkSectionElement from './LinkSectionElement';

import classes from './LinkSection.module.scss';

const container = {
  hidden: {},
  visible: {
    transition: {
      delayChildren: 3,
      staggerChildren: 0.05,
    },
  },
};

const ProjectsIcon = () => (
  <FaPencilRuler
    className={[
      classes['link__circle__icon'],
      classes['link__circle__icon--projects'],
    ].join(' ')}
  />
);

const ResumeIcon = () => (
  <BsFillFileEarmarkRichtextFill
    className={[
      classes['link__circle__icon'],
      classes['link__circle__icon--resume'],
    ].join(' ')}
  />
);

const ContactIcon = () => (
  <AiFillMessage
    className={[
      classes['link__circle__icon'],
      classes['link__circle__icon--contact'],
    ].join(' ')}
  />
);

const LinkSection = () => {
  const { setLandingPageViewed } = useContext(MainContext);
  useEffect(() => {
    setLandingPageViewed(true);
  }, []);

  return (
    <div className={classes.background}>
      <img
        src={animatedImages.alLogo}
        className={classes['alLogo']}
        alt="Al Logo"
      />
      <Typewriter />
      <motion.div
        className={classes['link__container']}
        variants={container}
        animate="visible"
        initial="hidden"
      >
        <LinkSectionElement
          icon={ProjectsIcon}
          text="Projects"
          animationKey="0"
          link="/projects"
        />
        <LinkSectionElement
          icon={ResumeIcon}
          text="Resume"
          animationKey="1"
          link="/resume"
        />
        <LinkSectionElement
          icon={ContactIcon}
          text="Contact"
          animationKey="2"
          link="/contact"
        />
      </motion.div>
      <motion.div className={classes['scroll__index']}
        animate={{ translateY: ['0%', '100%', '100%', '0%'] }}
        transition={{ ease: 'easeInOut', duration: 5, times: [0, 0.2, 0.4, 0.6], repeat: Infinity }}>
        <IoIosArrowDown style={{ scale: '180%', opacity: '0.6' }} />
      </motion.div>
    </div>
  );
};

export default LinkSection;
