import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import classes from './ScrollPresenter.module.scss';

const ScrollPresenter = (props) => {
  const imageRef = useRef();
  const textRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    const masterTl = gsap.timeline();

    const imageOpacity = gsap
      .timeline()
      .to(imageRef.current, { duration: 0.5, opacity: 1 });

    const textOpacity = gsap
      .timeline()
      .to(textRef.current, { duration: 1, opacity: 1 })
      .to(textRef.current, { duration: 1 });

    masterTl.add(imageOpacity, 1);
    masterTl.add(textOpacity, 2);

    const scrollPresenterTrigger = ScrollTrigger.create({
      animation: masterTl,
      trigger: contentRef.current,
      start: 'center center',
      end: '+=150%',
      scrub: 1,
      pin: true,
      refreshPriority: -1,
    });

    return () => {
      scrollPresenterTrigger.kill();
    };
  }, []);

  return (
    <div className={classes['container']}>
      <div ref={contentRef} className={classes['container__content']}>
        <img
          ref={imageRef}
          className={classes['container__content__image']}
          src={props.image}
          alt={props.imageAlt}
        />
        <p className={classes['container__content__text']} ref={textRef}>
          {props.children}
        </p>
      </div>
    </div>
  );
};

ScrollPresenter.propTypes = {
  image: PropTypes.any.isRequired,
  imageAlt: PropTypes.string.isRequired,
};

export default ScrollPresenter;
