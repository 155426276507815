import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion, useAnimation } from 'framer-motion';
import { Circle } from '../../components';

import classes from './TimelineItem.module.scss';
import { forwardRef } from 'react';
import { ACTIONS } from './Timeline';

const variants = {
  visible: {
    scale: [null, 1.8, 1.3],
    transition: {
      duration: 0.5,
      times: [0, 0.3, 1],
    },
  },
  hidden: {
    scale: [null, 1, 1.3],
    transition: {
      duration: 0.5,
      times: [0, 0.3, 1],
    },
  },
};

const TimelineItem = forwardRef((props, ref) => {
  const timelineRef = useRef(null);
  const timelineContainerRef = useRef(null);
  const contentBoxRef = useRef(null);
  const timelineProgressRef = useRef(null);
  const pointRef = useRef(null);
  const ioConfig = {
    rootMargin: '-30% 0% -70% 0%',
    threshold: 0,
  };

  const [currentColorState, setCurrentColorState] = useState(false);

  const circleAnimationControl = useAnimation();

  //on mount
  useEffect(() => {
    //init design state
    pointRef.current.style.borderColor = 'grey';
    circleAnimationControl.start('hidden');

    if (props.end) {
      timelineRef.current.className = classes['timeline__end'];
      timelineContainerRef.current.style.minHeight = 0;
    }

    //Changes the color of the progress timeline. Informs other components about the change.
    const changeTimelineColor = (entries, observer) => {
      if (props.end !== 'true') {
        if (entries[0].isIntersecting) {
          timelineProgressRef.current.style.height = '100%';
          props.dispatch({
            type: ACTIONS.COLORED,
            payload: { id: props.id + 1 },
          });
        } else if (entries[0].boundingClientRect.top > 0) {
          timelineProgressRef.current.style.height = '0%';
          props.dispatch({
            type: ACTIONS.UNCOLORED,
            payload: { id: props.id + 1 },
          });
        }
      }
    };

    const timelineIntersObs = new IntersectionObserver(
      changeTimelineColor,
      ioConfig
    );
    timelineIntersObs.observe(timelineContainerRef.current);

    return () => {
      timelineIntersObs.disconnect();
    };
  }, []);

  //triggers the dot animation and color change if previous elements line color was changed.
  useEffect(() => {
    if (currentColorState !== props.states[props.id].colored) {
      if (props.states[props.id].colored === true) {
        pointRef.current.style.borderColor = '#023E8A';
        circleAnimationControl.start('visible');
      } else {
        pointRef.current.style.borderColor = 'grey';
        circleAnimationControl.start('hidden');
      }
      setCurrentColorState(props.states[props.id].colored);
    }
  }, [props.states]);

  return (
    <div className={classes['timeline']} ref={timelineContainerRef}>
      <div className={classes['timeline__component']}></div>
      <div className={classes['timeline__middle']} ref={timelineRef}>
        {props.end !== 'true' && (
          <div
            className={classes['timeline__progress']}
            ref={timelineProgressRef}
          ></div>
        )}
        <div className={classes['timeline__point']}>
          <motion.div
            style={{ width: '100%', height: '100%' }}
            animate={circleAnimationControl}
            variants={variants}
          >
            <Circle ref={pointRef}></Circle>
          </motion.div>
        </div>
      </div>
      <div className={classes['timeline__content']}>
        <div className={classes['timeline__date']}>
          <div className={classes['timeline__date--begin']}>
            {props.beginDate}
          </div>
          {props.endDate !== undefined && props.todayActive !== true && (
            <span className={classes['timeline__date--end']}>
              to {props.endDate}
            </span>
          )}

          {props.todayActive && (
            <span className={[classes['timeline__date--end'], classes['timeline__date--stillActive']].join(' ')}>
              to Today
            </span>
          )}
        </div>
        <div className={classes['timeline__content__text']}>
          <div
            className={[
              classes['timeline__component'],
              classes['timeline__component--bg'],
            ].join(' ')}
            ref={contentBoxRef}
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
});

TimelineItem.defaultProps = {
  endDate: undefined,
  todayActive: false,
};

TimelineItem.propTypes = {
  beginDate: PropTypes.string,
  endDate: PropTypes.string,
  todayActive: PropTypes.bool,
};

export default TimelineItem;
