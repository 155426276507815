import React from 'react';
import PropTypes from 'prop-types';

import classes from './FullstackHoneycombElement.module.scss';
import { TbStack2, TbApi } from 'react-icons/tb';
import {
  DiReact,
  DiJavascript1,
  DiNodejsSmall,
  DiSass,
  DiHtml5,
  DiGit,
  DiMysql,
} from 'react-icons/di';
import HoneycombElement from '../../../../components/HoneycombGrid/HoneycombElement';
import { GradientText } from '../../../../components';

const JavaScriptIcon = () => <TbStack2 className={classes['icon']} />;

const FullstackHoneycombElement = (props) => {
  const gradientOne = '#48cae4';
  const gradientTwo = '#bc48ff';

  return (
    <HoneycombElement
      id={props.id}
      title="FullStack"
      category="Knowledge"
      icon={JavaScriptIcon}
      onFixPosition={props.onFixPosition}
      iconSizeAdapter="10%"
      titleColorFirst={gradientOne}
      titleColorSecond={gradientTwo}
    >
      <div className={classes['container']}>
        <h1 style={{ marginTop: '1em' }}>
          A stack{' '}
          <GradientText
            text="sooooooooo full"
            textFirstColor={gradientOne}
            textSecondColor={gradientTwo}
          />
          .
        </h1>
        <h1 style={{ marginTop: '0' }}>
          This website was created with it, full.
        </h1>
        <p>
          With expertise ranging from JavaScript to React, Sass to HTML, Node.js
          to MySQL, REST APIs, and Git, I possess a comprehensive understanding
          of various tech stacks. Continuously expanding my expertise, I am
          committed to staying updated with emerging technologies in the
          ever-evolving landscape of web development.
        </p>

        <div className={classes['container__icons']}>
          <div className={classes['container__icons--icon']}>
            <DiJavascript1
              className={[classes['icon'], classes['icon--js']].join(' ')}
            />
          </div>
          <div className={classes['container__icons--icon']}>
            <DiReact
              className={[classes['icon'], classes['icon--react']].join(' ')}
            />
          </div>
          <div className={classes['container__icons--icon']}>
            <DiNodejsSmall
              className={[classes['icon'], classes['icon--node']].join(' ')}
            />
          </div>
          <div className={classes['container__icons--icon']}>
            <DiHtml5
              className={[classes['icon'], classes['icon--html']].join(' ')}
            />
          </div>
          <div className={classes['container__icons--icon']}>
            <DiSass
              className={[classes['icon'], classes['icon--sass']].join(' ')}
            />
          </div>

          <div className={classes['container__icons--icon']}>
            <DiMysql
              className={[classes['icon'], classes['icon--sql']].join(' ')}
            />
          </div>
          <div className={classes['container__icons--icon']}>
            <TbApi
              className={[classes['icon'], classes['icon--api']].join(' ')}
            />
          </div>
          <div className={classes['container__icons--icon']}>
            <DiGit
              className={[classes['icon'], classes['icon--git']].join(' ')}
            />
          </div>
        </div>
      </div>
    </HoneycombElement>
  );
};

FullstackHoneycombElement.propTypes = {
  id: PropTypes.string.isRequired,
  onFixPosition: PropTypes.func.isRequired,
};

export default FullstackHoneycombElement;
