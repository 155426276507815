import React from 'react';

import classes from './Circle.module.scss';


const Circle = (props, ref) => {
  return (
      <div style={props.style} ref={ref} className={classes.circle}>
        {props.children}
      </div>
  );
};

export default React.forwardRef(Circle);
