import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import classes from './MediaCursor.module.scss';

const MediaCursor = (props) => {
  const style = {
    title: {
      fontSize: props.fontSize,
      color: props.textColor,
    },
    container: {
      //backgroundColor: props.backgroundColor,
    },
    cursor: {
      backgroundColor: props.selectionBackgroundColor,
    },
  };

  const containerRef = useRef();

  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  const [cursorVariant, setCursorVariant] = useState('default');

  useEffect(() => {
    //Sets color variables.
    containerRef.current.style.setProperty(
      '--selection-background-color',
      props.selectionBackgroundColor
    );
    containerRef.current.style.setProperty(
      '--selection-color',
      props.selectionColor
    );

    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener('mousemove', mouseMove);

    return () => {
      window.removeEventListener('mousemove', mouseMove);
    };
  }, []);

  const variants = {
    default: {
      height: 0,
      width: 0,
      x: mousePosition.x,
      y: mousePosition.y,
    },
    text: {
      height: 150,
      width: 150,
      x: mousePosition.x - 75,
      y: mousePosition.y - 75,
      backgroundColor: props.backgroundColor,
      mixBlendMode: 'difference',
    },
    onSection: {
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
      height: 32,
      width: 32,
    },
  };

  const textEnter = () => setCursorVariant('text');
  const textLeave = () => setCursorVariant('onSection');
  const sectionEnter = () => setCursorVariant('onSection');
  const sectionLeave = () => setCursorVariant('default');

  return (
    <div
      style={style.container}
      ref={containerRef}
      onMouseEnter={sectionEnter}
      onMouseLeave={sectionLeave}
      className={classes['container']}
    >
      <div
        onMouseEnter={textEnter}
        onMouseLeave={textLeave}
        style={style.title}
        className={classes['title']}
      >
        {props.children}
      </div>
      <motion.div
        style={style.cursor}
        variants={variants}
        className={classes['cursor']}
        animate={cursorVariant}
      ></motion.div>
    </div>
  );
};

MediaCursor.defaultProps = {
  text: 'Who am I',
  textColor: 'black',
  selectionColor: 'white',
  selectionBackgroundColor: 'black',
  fontFamily: 'var(--font-base)',
  fontSize: '20vw',
  backgroundColor: 'yellow',
};

MediaCursor.propTypes = {
  text: PropTypes.string,
  textColor: PropTypes.string,
  selectionColor: PropTypes.string,
  selectionBackgroundColor: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default MediaCursor;
