import React from 'react';

import classes from './MusicGameSection.module.scss';
import { images, animatedImages } from '../../constants';
import { TextWrapper } from '../../components';
import { ZoomImageContainer } from '../../container';

const MusicGameSection = () => {
  return (
    <div style={{ width: '100%', height: '100vh', minHeight: '600px' }}>
      <ZoomImageContainer
        backgroundColor="black"
        containerImage={images.musicGameBackground}
      >
        <div className={classes['container']}>
          <div className={classes['container__content']}>
            <div className={classes['container__content__text']}>
              Music Game
              <div className={classes['container__content__text--wrapper']}>
                <TextWrapper
                  text="In school, I embarked on a music adventure game project for my music class. Its objective was to creatively teach different scales through gameplay. I took it a step further by personally crafting the music and graphics for this project."
                  fontSize="0.3em"
                  fontColor="#212529"
                  fontFamily="--font-game"
                  maxWidth="60vw"
                  textLength={55}
                />
              </div>
            </div>
          </div>
          <img
            className={classes['container__images--fomka']}
            src={animatedImages.musicGameFomka}
            alt="Hamster"
          />
          <img
            className={classes['container__images--player']}
            src={animatedImages.musicGamePlayer}
            alt="Player"
          />
        </div>
      </ZoomImageContainer>
    </div>
  );
};

export default MusicGameSection;
