import csharp from '../assets/csharp.svg';
import waveBack from '../assets/waveBack.svg';
import waveMiddle from '../assets/waveMiddle.svg';
import premierePro from '../assets/premierePro.svg';
import afterEffects from '../assets/afterEffects.svg';
import photoshop from '../assets/photoshop.svg';
import illustrator from '../assets/illustrator.svg';
import designSectionBackground from '../assets/designSectionBackground.svg';
import vectornator from '../assets/vectornator.jpg';
import waveFront from '../assets/waveFront.svg';
import iPadTable from '../assets/iPadTable.png';
import bwinfLogo from '../assets/bwinfLogo.png';
import politicsBackground from '../assets/politicsBackground.png';
import politicsMe from '../assets/politicsMe.png';
import bwinfCompetition from '../assets/bwinfCompetition.svg';
import jgCompetition from '../assets/jgCompetition.svg';
import simplic from '../assets/simplic.svg';
import ipad from '../assets/ipad.png';
import applePencil from '../assets/applePencil.png';
import meBundestag from '../assets/meBundestag.jpg';
import musicGameBackground from '../assets/musicGameBackground.png';
import resumeTitle from '../assets/resumeTitle.png';
import alexEU from '../assets/EUAlex.png';

export default {
  csharp,
  waveBack,
  waveMiddle,
  waveFront,
  iPadTable,
  bwinfLogo,
  premierePro,
  afterEffects,
  photoshop,
  vectornator,
  designSectionBackground,
  politicsBackground,
  politicsMe,
  illustrator,
  bwinfCompetition,
  jgCompetition,
  simplic,
  ipad,
  applePencil,
  meBundestag,
  musicGameBackground,
  resumeTitle,
  alexEU,
};
