import React, { useState, useEffect, useRef } from 'react';
import {FooterSection} from '../../section';
import emailjs from '@emailjs/browser';

import classes from './ContactMe.module.scss';

const ContactMe = () => {
  const formRef = useRef();

  let valid = false;

  const [input, setInput] = useState({});
  const [inputError, setInputError] = useState({});
  const [notification, setNotification] = useState({});
  const [messageColor, setMessageColor] = useState('#06d85f');
  const [messageVisible, setMessageVisible] = useState(0);
  const [messageOpacity, setMessageOpacity] = useState('hidden');

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInput((values) => ({ ...values, [name]: value }));
  };

  const handleToggle = (event) => {
    const name = event.target.name;
    setInput((values) => ({ ...values, [name]: !input[name] }));
  };

  const regexEmailValidate = (address) => {
    return address.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validate = (values) => {
    const errors = {};
    valid = true;

    if (!values.firstName) {
      errors.firstName = 'First name is required!';
      valid = false;
    }
    if (!values.emailAddress) {
      errors.emailAddress = 'Email is required!';
      valid = false;
    }
    if (!values.lastName) {
      errors.lastName = 'Last name is required!';
      valid = false;
    }

    if (values.emailAddress) {
      if (!regexEmailValidate(input.emailAddress)) {
        errors.emailAddress = 'Email is invalid!';
        valid = false;
      }
    }

    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setInputError(validate(input));

    if (valid) {
      emailjs
        .sendForm(
          'service_mn5fdmk',
          'template_anivj7a',
          formRef.current,
          'yVmX_WxPAbr563OZ7'
        )
        .then(
          (result) => {
            if (result.text === 'OK') {
              const not = {};

              not.title = 'Success!';
              not.text = 'Your message was successfully sent!';
              setMessageColor('#06d85f');
              setNotification(not);
              setInput({});
            }
          },
          (error) => {
            const not = {};

            not.title = 'Error!';
            not.text =
              'Something went wrong. Please send a direct email to contact@alexloran.com';
            setMessageColor('red');
            setNotification(not);
          }
        );
      setMessageVisible(1);
      setMessageOpacity('visible');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className={classes['section']}>
        <div className={classes['container']}>
          <div
            style={{ opacity: messageVisible, visibility: messageOpacity }}
            className={classes['overlay']}
          >
            <div className={classes['overlay__popup']}>
              <h2 style={{ color: messageColor }}>{notification.title}</h2>
              <p
                className={classes['overlay__popup--close']}
                href="#"
                onClick={() => {
                  if (messageVisible === 1) {
                    setMessageVisible(0);
                    setMessageOpacity('hidden');
                  } else {
                    setMessageVisible(1);
                    setMessageOpacity('visible');
                  }
                  setNotification({})
                }}
              >
                &times;
              </p>
              {!notification.text && <div className={classes['loader']}></div>}
              <div className={classes['overlay__popup--content']}>
                {notification.text}
              </div>
            </div>
          </div>
          <div className={classes['container__heading']}>
            <h1>Looking for some contact?</h1>
            <p>Fill out the form below and we will get in touch!</p>
            <p style={{ marginBottom: '32px' }}>
              Or write directly an email to contact@alexloran.com
            </p>
          </div>

          <form
            ref={formRef}
            onSubmit={handleSubmit}
            className={classes['container__form']}
          >
            <div className={classes['container__form__group']}>
              <label className={classes['container__form__group--label']}>
                First name* <em>{inputError.firstName}</em>
                <input
                  className={[classes['container__form__group--input']].join(
                    ' '
                  )}
                  type="text"
                  name="firstName"
                  value={input.firstName || ''}
                  onChange={handleChange}
                  placeholder="First name"
                />
              </label>
            </div>
            <div className={classes['container__form__group']}>
              <label className={classes['container__form__group--label']}>
                Last name* <em>{inputError.lastName}</em>
                <input
                  className={classes['container__form__group--input']}
                  type="text"
                  name="lastName"
                  value={input.lastName || ''}
                  onChange={handleChange}
                  placeholder="Last name"
                />
              </label>
            </div>
            <div className={classes['container__form__group']}>
              <label className={classes['container__form__group--label']}>
                Email address* <em>{inputError.emailAddress}</em>
                <input
                  className={classes['container__form__group--input']}
                  type="text"
                  name="emailAddress"
                  value={input.emailAddress || ''}
                  onChange={handleChange}
                  placeholder="youremial@example.com"
                />
              </label>
            </div>
            <div className={classes['container__form__group']}>
              <label className={classes['container__form__group--label']}>
                Company name
                <input
                  className={classes['container__form__group--input']}
                  type="text"
                  name="companyName"
                  value={input.companyName || ''}
                  onChange={handleChange}
                  placeholder="Company name"
                />
              </label>
            </div>
            <div className={classes['container__form__group--big']}>
              <label className={classes['container__form__group--label']}>
                What are you looking for?
                <textarea
                  className={[
                    classes['container__form__group--input'],
                    classes['container__form__group--textarea'],
                  ].join(' ')}
                  name="message"
                  value={input.message || ''}
                  onChange={handleChange}
                  placeholder="Type your message here..."
                />
              </label>
            </div>
            <div className={classes['container__form__group--big']}>
              <label className={classes['container__form__group--label']}>
                <input
                  required={true}
                  type="checkbox"
                  name="agreeWithTerms"
                  checked={input.agreeWithTerms || false}
                  onChange={handleToggle}
                />{' '}
                I agree with the{' '}
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  terms and conditions
                </a>{' '}
                and the{' '}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  privacy policy
                </a>
                .
              </label>
            </div>
            <div className={classes['container__form__submit']}>
              <input value="SEND MESSAGE" type="submit" />
            </div>
          </form>
        </div>
      </section>
      <FooterSection />
    </>
  );
};

export default ContactMe;
