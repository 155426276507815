import React from 'react';
import PropTypes from 'prop-types';

import classes from './GradientText.module.scss';

const GradientText = (props) => {
  let style;

  if (props.clip) {
    style = {
      text: {
        background: `-webkit-linear-gradient(58deg, ${props.textFirstColor} 0%, ${props.textSecondColor} 100%)`,
        background: `linear-gradient(58deg, ${props.textFirstColor} 0%, ${props.textSecondColor} 100%)`,
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        fontSize: props.fontSize,
        fontFamily: `var(${props.fontFamily})`,
      },
    };
  } else {
    style = {
      text: {
        background: `-webkit-linear-gradient(58deg, ${props.textFirstColor} 0%, ${props.textSecondColor} 100%)`,
        background: `linear-gradient(58deg, ${props.textFirstColor} 0%, ${props.textSecondColor} 100%)`,
        fontSize: props.fontSize,
        color: props.color,
        fontFamily: `var(${props.fontFamily})`,
      },
    };
  }

  return (
    <span style={style.text} className={classes['text']}>
      {props.text}
    </span>
  );
};

GradientText.defaultProps = {
  textFirstColor: '#48cae4',
  textSecondColor: '#bc48ff',
  fontFamily: '--font-base',
  fontSize: '1em',
  clip: true,
  color: 'white',
};

GradientText.propTypes = {
  text: PropTypes.string.isRequired,
  textFirstColor: PropTypes.string,
  textSecondColor: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  clip: PropTypes.bool,
  color: PropTypes.string,
};

export default GradientText;
