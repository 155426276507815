import { Mark, Underline } from '../../components';
import TimelineDefaultTemplate from './TimelineTemplate/TimelineDefaultTemplate';

const ResumeData = [
  {
    beginDate: 'Apr. 2023',
    template: (
      <TimelineDefaultTemplate
        title={
          <p>
            Internship at the EU <Mark fontSize="1em">Parliament</Mark>
          </p>
        }
        location="Brussels"
      >
        <p>
          Throughout my time in the European Parliament, I gained valuable
          insights into international politics, with a focus on:
        </p>
        <ul>
          <li>international trade</li>
          <li>economic and monetary affairs</li>
          <li>foreign affairs</li>
        </ul>
      </TimelineDefaultTemplate>
    ),
  },
  {
    beginDate: 'Nov. 2022',
    endDate: 'Jan. 2023',
    template: (
      <TimelineDefaultTemplate
        title={<p>International Political Travel</p>}
        location="Azerbaijan, Georgia, Caribbeans"
      ></TimelineDefaultTemplate>
    ),
  },
  {
    beginDate: 'Oct. 2022',
    todayActive: true,
    template: (
      <TimelineDefaultTemplate title="Beginning of my academic studies in economics at the University"></TimelineDefaultTemplate>
    ),
  },

  {
    beginDate: 'Oct. 2022',
    template: (
      <TimelineDefaultTemplate
        title={
          <p>
            Internship at the German <Mark fontSize="1em">Bundestag</Mark>
          </p>
        }
        location="Berlin"
      >
        <p>
          Throughout my time in the Bundestag, I gained valuable insights with a
          specific focus on the economic and energy political challenges that
          shape Germany's future
        </p>
      </TimelineDefaultTemplate>
    ),
  },
  {
    beginDate: 'May 2022',
    todayActive: true,
    template: (
      <TimelineDefaultTemplate
        title={
          <p>
            <Mark fontSize="1em">Board member</Mark> of a political youth
            organization
          </p>
        }
        location="Hildesheim"
      ></TimelineDefaultTemplate>
    ),
  },
  {
    beginDate: 'May 2022',
    template: (
      <TimelineDefaultTemplate
        title={
          <p>
            Graduated Gymnasium Himmelsthür with{' '}
            <Mark fontSize="1em">special recognition</Mark>
          </p>
        }
        location="Hildesheim"
      >
        <p>
          I have attained an Abitur with a commendation for outstanding
          achievement in the discipline of computer science.
        </p>
      </TimelineDefaultTemplate>
    ),
  },
  {
    beginDate: 'Jan. 2022',
    template: (
      <TimelineDefaultTemplate
        title={
          <p>
            <Mark fontSize="1em">Top 1%</Mark> at Jugend Gründet
          </p>
        }
        location="Hildesheim"
      >
        <p>
          I was among the top 1% in the german federal economics competition.
        </p>
      </TimelineDefaultTemplate>
    ),
  },
  {
    beginDate: 'Sep. 2021',
    endDate: 'May. 2023',
    template: (
      <TimelineDefaultTemplate
        title="Political Congresses"
        location="Berlin, Dresden, Rügen, Mannheim"
      >
        <p>Participated at:</p>

        <ul>
          <li>Politics congress in Berlin</li>
          <li>UN sustainable development goals youth congress in Rügen</li>
          <li>Congress with the Federal President of Germany in Berlin</li>
          <li>Political discussion event in Dresden</li>
          <li>Congress on consumer rights and protection in Berlin</li>
          <li>UN sustainable development goals congress in Mannheim</li>
          <li>Federal conference in Berlin</li>
          <li>Federal conference on security politics in Berlin</li>
          <li>etc...</li>
        </ul>
      </TimelineDefaultTemplate>
    ),
  },
  {
    beginDate: 'Sep. 2021',
    todayActive: true,
    template: (
      <TimelineDefaultTemplate
        title="Volunteer at the Johanniter Unfall Hilfe"
        location="Hildesheim"
      >
        <p>Tasks</p>

        <ul>
          <li>Paramedic Assistant</li>
        </ul>
      </TimelineDefaultTemplate>
    ),
  },

  {
    beginDate: 'Aug. 2020',
    todayActive: true,
    template: (
      <TimelineDefaultTemplate
        title={
          <p>
            <Mark fontSize="1em">Softwaredeveloper</Mark> at Simplic GmbH
          </p>
        }
        location="Hildesheim"
      >
        <p style={{ paddingBottom: '0.5em' }}>
          With over{' '}
          <Underline fontSize="1em" markPosition="-75%">
            2 years
          </Underline>{' '}
          of experience
        </p>
        <p>Tasks</p>

        <ul>
          <li>Softwaredevelopment .Net</li>
          <li>Softwarearchitecture</li>
        </ul>
      </TimelineDefaultTemplate>
    ),
  },
  {
    beginDate: 'Sept. 2019',
    endDate: 'April 2020',
    template: (
      <TimelineDefaultTemplate
        title="Participated at federal computer science competition BWINF"
        location="Hildesheim"
      >
        <ul>
          <li>2. prize in the first round</li>
          <li>2. prize in the second round</li>
          <li>
            Won participation at "Fit-for-BWINF" camp at the
            Hasso-Plattner-Institute
          </li>
        </ul>
      </TimelineDefaultTemplate>
    ),
  },
  {
    beginDate: 'Sept. 2019',
    endDate: 'Oct. 2019',
    end: true,
    template: (
      <TimelineDefaultTemplate
        title="Student internship at Bosch"
        location="Hildesheim"
      >
        <p>Departments:</p>
        <ul>
          <li>Manufacturing Process</li>
          <li>Manufacturing Sector and Engineering</li>
        </ul>
      </TimelineDefaultTemplate>
    ),
  },
];

export default ResumeData;
