import React, { useRef, useEffect, useReducer } from 'react';
import { TimelineItem } from '../';

import classes from './Timeline.module.scss';
import ResumeData from './ResumeData';

export const ACTIONS = {
  COLORED: 'setColored',
  UNCOLORED: 'setUncolored',
};

//informs the next component, that the current one was colored.
function reducer(states, action) {
  switch (action.type) {
    case ACTIONS.COLORED:
      return states.map((state) => {
        if (state.id === action.payload.id) {
          return { ...state, colored: true };
        }
        return state;
      });
    case ACTIONS.UNCOLORED:
      return states.map((state) => {
        if (state.id === action.payload.id) {
          return { ...state, colored: false };
        }
        return state;
      });
    default:
      return states;
  }
}

const Timeline = () => {
  const firstTimelineItemRef = useRef(null);

  const initialList = [];
  for (let i = 0; i < ResumeData.length; i++) {
    initialList.push({ id: i, colored: false });
  }

  const [states, dispatch] = useReducer(reducer, initialList);

  const ioConfig = {
    rootMargin: '-50% 0% -50% 0%',
    threshold: 0,
  };

  const changeStartPointColor = (entries, observer) => {
    if (entries[0].isIntersecting) {
      dispatch({
        type: ACTIONS.COLORED,
        payload: { id: 0 },
      });
    } else if (entries[0].boundingClientRect.top > 0) {
      dispatch({
        type: ACTIONS.UNCOLORED,
        payload: { id: 0 },
      });
    }
  };

  const timelineIntersObs = new IntersectionObserver(
    changeStartPointColor,
    ioConfig
  );

  useEffect(() => {
    timelineIntersObs.observe(firstTimelineItemRef.current);
    return () => {
      timelineIntersObs.disconnect();
    };
  }, []);

  return (
    <div className={classes.timeline}>
      <div ref={firstTimelineItemRef}>
        {ResumeData.map((resumeItem, index) => (
          <TimelineItem
            key={index}
            dispatch={dispatch}
            states={states}
            id={index}
            beginDate={resumeItem.beginDate}
            endDate={resumeItem.endDate}
            todayActive={resumeItem.todayActive}
            end={resumeItem.end}
          >
            {resumeItem.template}
          </TimelineItem>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
