import React, {useEffect} from 'react';

import { Landing } from './container';

import './App.scss';

function App() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Landing />
    </div>
  );
}

export default App;