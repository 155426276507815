import alLogo from '../assets/alLogo.png';
import projects from '../assets/projects.png';
import musicGameFomka from '../assets/musicGameFomka.png';
import musicGamePlayer from '../assets/musicGamePlayer.png';

export default {
  alLogo,
  projects,
  musicGameFomka,
  musicGamePlayer,
}