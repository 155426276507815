import React from 'react'

import classes from './LinkButton.module.scss';

const LinkButton = (props, ref) => {
  return (
    <div ref={ref} className={classes['circle']}>
      {props.children}
    </div>
  )
}

export default React.forwardRef(LinkButton);