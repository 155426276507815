import React from 'react';
import PropTypes from 'prop-types';

import { FaJava } from 'react-icons/fa';
import { DiAndroid } from 'react-icons/di';

import classes from './JavaHoneycombElement.module.scss';
import HoneycombElement from '../../../../components/HoneycombGrid/HoneycombElement';
import { GradientText } from '../../../../components';

const JavaIcon = () => <FaJava className={classes['icon']} />;

const JavaHoneycombElement = (props) => {
  const gradientOne = '#E97B18';
  const gradientTwo = '#ffb700';

  const Gradient = (text) => (
    <GradientText
      text={text}
      textFirstColor={gradientOne}
      textSecondColor={gradientTwo}
    />
  );
  const GradientSecond = (text) => (
    <GradientText
      text={text}
      textFirstColor="#ffbf00"
      textSecondColor={gradientOne}
    />
  );

  return (
    <HoneycombElement
      id={props.id}
      title="Java"
      category="Language"
      icon={JavaIcon}
      onFixPosition={props.onFixPosition}
      iconSizeAdapter="10%"
      titleColorFirst={gradientOne}
      titleColorSecond={gradientTwo}
    >
      <div className={classes['container']}>
        <h1 style={{ marginTop: '1em' }}>
          Used for solving {Gradient('complex algorithmic problems')}.
        </h1>
        <p>
          Java is my former main language for developing algorithmic solutions
          and desktop applications, including games.
        </p>
        <h2>
          Successfully finished {Gradient('Round 2')} of {Gradient('Bwinf')}
        </h2>
        <p>
          Bwinf is Germany's official federal coding competition, challenging
          participants to solve {GradientSecond('complex algorithmic problems')}
          . I successfully participated in Round 2, utilizing Java as my
          language of choice.
        </p>
        <div className={classes['android']}>
          <DiAndroid className={classes['icon--android']} />
          <div>
            <h2>Android </h2>
            <p>
              Having gained experience in developing Android apps using Java, I
              have now transitioned to working with React Native for app
              development.
            </p>
          </div>
        </div>
      </div>
    </HoneycombElement>
  );
};

JavaHoneycombElement.propTypes = {
  id: PropTypes.string.isRequired,
  onFixPosition: PropTypes.func.isRequired,
};

export default JavaHoneycombElement;
