import React, { useEffect, useRef, useState } from 'react';
import { TextRotator } from '../../components';

import classes from './DeveloperDetailsLandingSection.module.scss';
import MainDevHoneycombGrid from './HoneycombElements/MainDevHoneycombGrid';

const DeveloperDetailsLandingSection = () => {
  const detailsContainerRef = useRef();

  //indicates when DOM has loaded.
  const [Loaded, setLoaded] = useState(false);

  const disable = () => {
    // To get the scroll position of current webpage
    const topScroll = window.pageYOffset || document.documentElement.scrollTop;
    const leftScroll =
      window.pageXOffset || document.documentElement.scrollLeft;

    const verticalContainerPosition =
      topScroll + detailsContainerRef.current.getBoundingClientRect().top;

    window.scrollTo(leftScroll, verticalContainerPosition);

    document.body.classList.add('disable-scroll');
  };

  const enable = () => {
    document.body.classList.remove('disable-scroll');
  };

  const fFixPosition = (fixed) => {
    if (!fixed) disable();
    else enable();
  };

  const ioConfig = {
    rootMargin: '50% 0% 50% 0%',
    threshold: 0,
  };

  const intersectionAction = (entries, observer) => {
    if (entries[0].isIntersecting) {
      setLoaded('true');
    } else if (entries[0].boundingClientRect.top > 0) {
      setLoaded('false');
    }
  };

  const timelineIntersObs = new IntersectionObserver(
    intersectionAction,
    ioConfig
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      enable();
    });
    timelineIntersObs.observe(detailsContainerRef.current);
    return () => {
      timelineIntersObs.disconnect();
      window.removeEventListener('resize', () => {
        enable();
      });
    };
  }, []);

  return (
    <div className={classes['container']} ref={detailsContainerRef}>
      {Loaded && (
        <div className={classes['container__inner']}>
          <div className={classes['rota']}>
            <TextRotator />
          </div>

          <div className={classes['honeycomb']}>
            <div className={classes['honeycomb__hint']}>
              <h2>Select a tile</h2>
            </div>

            <MainDevHoneycombGrid onFixPosition={fFixPosition} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DeveloperDetailsLandingSection;
