import React, { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import {
  LandingThree,
} from '../../components';
import LinkSection from '../LinkSection/LinkSection';
import {
  DesignSection,
  DeveloperLandingSection,
  ExperienceSection,
} from '../../section';
import DeveloperDetailsLandingSection from '../../section/DeveloperDetailsLandingSection/DeveloperDetailsLandingSection';

import classes from './Landing.module.scss';
import PoliticsSection from '../../section/PoliticsSection/PoliticsSection';
import FooterSection from '../../section/FooterSection/FooterSection';

const Landing = () => {
  gsap.registerPlugin(ScrollTrigger);

  const landingThreeRef = useRef();
  const threeBottomRef = useRef();
  const devSectionTitleRef = useRef();
  const devSectionOpacityRef = useRef();
  const devSectionContainerRef = useRef();
  const devSectionDetailsRef = useRef();
  const [threeScrollProgress, setThreeScrollProgress] = useState(0);
  const [threeAfterRotateProgress, setthreeAfterRotateProgress] = useState(0);

  useEffect(() => {
    //Three Js animation
    const landingThreeTrigger = ScrollTrigger.create({
      trigger: landingThreeRef.current,
      onUpdate: (trigger) => setThreeScrollProgress(trigger.progress),
      start: 'top top',
      end: '+=50%',
      scrub: true,
      pin: true,
      anticipatePin: 1,
      refreshPriority: 2,
    });

    const threeBottomTrigger = ScrollTrigger.create({
      trigger: threeBottomRef.current,
      onUpdate: (trigger) => setthreeAfterRotateProgress(trigger.progress),
      start: 'bottom bottom',
      scrub: true,
    });

    //Dev Section animation
    const masterTl = gsap.timeline();

    const devTitleContainerTl = gsap
      .timeline()
      .to(devSectionTitleRef.current, { duration: 1, opacity: 0 });

    const devSectionOpacityTl = gsap
      .timeline()
      .to(devSectionOpacityRef.current, { duration: 1, opacity: 0 });

    const devTitleAnimationTl = gsap
      .timeline()
      .to(devSectionTitleRef.current.children[0], {
        duration: 2,
        fontSize: '100vw',
      });

    const devTitleCursorAnimationTl = gsap
      .timeline()
      .to(devSectionTitleRef.current.children[0].children[0], {
        duration: 2,
        fontSize: '100vw',
      });

    const devSectionDetailsAnimationTl = gsap
      .timeline()
      .to(devSectionDetailsRef.current, { duration: 0, visibility: 'visible' })
      .to(devSectionDetailsRef.current, { duration: 2, opacity: 1 })
      .to(devSectionDetailsRef.current, { duration: 2 });

    masterTl.add(devTitleAnimationTl, 1);
    masterTl.add(devTitleCursorAnimationTl, '<');
    masterTl.add(devTitleContainerTl, 2);
    masterTl.add(devSectionOpacityTl, 2);
    masterTl.add(devSectionDetailsAnimationTl, 2);
    const devSectionTrigger = ScrollTrigger.create({
      animation: masterTl,
      trigger: devSectionContainerRef.current,
      start: 'top top',
      end: '+=100%',
      scrub: 1,
      pin: true,
      anticipatePin: 1,
      refreshPriority: 1,
    });

    setTimeout(ScrollTrigger.refresh(), 1);

    return () => {
      devSectionTrigger.kill();
      threeBottomTrigger.kill();
      landingThreeTrigger.kill();
    };
  }, []);

  return (
    <div className="app">
      <LinkSection />
      <section style={{ backgroundColor: 'black', height: '150vh' }}>
        <div ref={landingThreeRef} style={{ height: '100vh' }}>
          <LandingThree
            animationProgress={threeScrollProgress}
            afterRotateProgress={threeAfterRotateProgress}
          />
        </div>
        <div ref={threeBottomRef}></div>
      </section>
      <section style={{ height: '200vh' }}>
        <div
          ref={devSectionContainerRef}
          style={{ height: '100vh', overflow: 'hidden' }}
        >
          <div
            ref={devSectionDetailsRef}
            className={[classes['default__div'], classes['dev__details']].join(
              ' '
            )}
          >
            <DeveloperDetailsLandingSection />
          </div>
          <div ref={devSectionOpacityRef} className={classes['default__div']}>
            <DeveloperLandingSection ref={devSectionTitleRef} />
          </div>
        </div>
      </section>

      <ExperienceSection />
      <DesignSection />
      <PoliticsSection />
      <FooterSection/>
    </div>
  );
};

export default Landing;
