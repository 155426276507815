import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import classes from './HoneycombClosed.module.scss';

const HoneycombClosed = (props) => {
  const styles = {
    cardContent: {
      //background: "linear-gradient(145deg, #d4d4d4, #fbfbfb)",
    },
  };

  const Icon = props.icon;

  return (
    <>
      <motion.div className={classes['card']}>
        <motion.div className={classes['card__container']}>
          <motion.div
            style={styles.cardContent}
            className={classes['card__container__content']}
            layoutId={`card-container-${props.id}`}
          >
            <motion.div
              className={classes['card__icon']}
            >
              <motion.div layoutId={`icon-container-${props.id}`} className={classes['card__icon__style']}>
                <Icon />
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  );
};

HoneycombClosed.defaultProps = {
};

HoneycombClosed.propTypes = {
  icon: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default HoneycombClosed;