import { useScroll } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import useRefs from 'react-use-refs';
import React, { useEffect } from 'react';
import { gsap } from 'gsap';

const AsciiScene = (props, ref) => {
  //const scroll = useScroll();
  const [iMeshRef] = useRefs();
  const { invalidate } = useThree();

  useFrame((state, delta) => {
    const r1 =
      props.animationProgress < 3 / 4 ? props.animationProgress / (3 / 4) : 1;
    const r2 = props.animationProgress;

    iMeshRef.current.rotation.x = r2 * 5 + props.afterRotateProgress * 5;
    iMeshRef.current.rotation.y = r2 * 3 + props.afterRotateProgress * 3;
    iMeshRef.current.rotation.z = r2 * 3 + props.afterRotateProgress * 3;

    state.camera.position.set(r1 * 3, 1, 1);
    state.camera.lookAt(2 - r1 * 2, 0, 0);
  });

  useEffect(() => {
    const mesh = iMeshRef.current;
    mesh.addEventListener('resize', invalidate);
    return () => {
      mesh.removeEventListener('resize', invalidate);
    };
  }, []);

  return (
    <>
      <directionalLight position={[-2, 5, 4]} intensity={0.9} />
      <directionalLight position={[0, -1, -3]} intensity={0.9} />
      <ambientLight intensity={1} />

      <mesh ref={iMeshRef} rotation={[9, 0, 20]}>
        <icosahedronGeometry attach="geometry" />
        <meshStandardMaterial
          wireframe
          wireframeLinewidth={3}
          attach="material"
          color="white"
        />
      </mesh>
    </>
  );
};

export default AsciiScene;