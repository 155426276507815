import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import classes from './ExperienceSection.module.scss';
import { images } from '../../constants';
import { ZoomContainer } from '../../container';
import ScrollPresenter from '../../components/ScrollPresenter/ScrollPresenter';

const ExperienceSection = () => {
  gsap.registerPlugin(ScrollTrigger);

  const containerRef = useRef();
  const titleRef = useRef();

  useEffect(() => {
    const titleOpacity = gsap
      .timeline()
      .to(titleRef.current, { duration: 0.3, opacity: 1 })
      .to(titleRef.current, { duration: 0.7 });

    const titleTrigger = ScrollTrigger.create({
      animation: titleOpacity,
      trigger: titleRef.current,
      start: 'center 20%',
      end: '+=130%',
      scrub: 1,
      pin: true,
      refreshPriority: -1,
    });

    return () => {
      titleTrigger.kill();
    };
  }, []);

  return (
    <div ref={containerRef} className={classes['container']}>
      <ZoomContainer backgroundColor="white" containerColor="black">
        <div className={classes['container__content']}>
          <div className={classes['container__content__competition']}>
            <div
              ref={titleRef}
              className={classes['container__content__competition--title']}
            >
              Participated Successfully
            </div>
            <div
              className={classes['container__content__competition--elements']}
            >
              <ScrollPresenter image={images.bwinfCompetition} imageAlt="bundeswettbewerb informatik">
                Succeeded in{' '}
                <em className={classes['container__content--yellow']}>
                  Round 2
                </em>
              </ScrollPresenter>

              <ScrollPresenter image={images.jgCompetition} imageAlt="jugend gruendet competition">
                In the{' '}
                <em className={classes['container__content--orange']}>
                  top 1%
                </em>
              </ScrollPresenter>
            </div>
          </div>
          <div className={classes['container__content--experience']}>
            <ScrollPresenter image={images.simplic} imageAlt="simplic gmbh company">
              <em className={classes['container__content--green']}>+2 Years</em>{' '}
              of{' '}
              <em className={classes['container__content--green']}>
                Work Experience
              </em>{' '}
              as a Software Developer
            </ScrollPresenter>
          </div>
          <div className={classes['container__content--spacer']}></div>
        </div>
      </ZoomContainer>
    </div>
  );
};

export default ExperienceSection;
