import React from 'react';
import { FaGithub, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';

import classes from './FooterSection.module.scss';

const FooterSection = () => {
  const year = new Date().getFullYear();

  return (
    <section>
      <div className={classes['container']}>
        <div className={classes['container__socials']}>
          <a
            href="mailto:contact@alexloran.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            <li className={classes['container__socials__items']}>
              <MdOutlineEmail
                className={classes['container__socials__items--icons']}
              />
            </li>
          </a>
          <a
            href="https://www.instagram.com/alexander.loran/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <li className={classes['container__socials__items']}>
              <FaInstagram
                className={classes['container__socials__items--icons']}
              />
            </li>
          </a>
          <a
            href="https://github.com/gevari"
            target="_blank"
            rel="noreferrer noopener"
          >
            <li className={classes['container__socials__items']}>
              <FaGithub
                className={classes['container__socials__items--icons']}
              />
            </li>
          </a>
          <a
            href="https://www.linkedin.com/in/alexander-loran-63a39823b"
            target="_blank"
            rel="noreferrer noopener"
          >
            <li className={classes['container__socials__items']}>
              <FaLinkedinIn
                className={classes['container__socials__items--icons']}
              />
            </li>
          </a>
        </div>

        <div className={classes['container__pages']}>
          <Link to="/">
            <li className={classes['container__pages__items']}>Home</li>
          </Link>
          <Link to="/contact">
            <li className={classes['container__pages__items']}>Contact</li>
          </Link>
          <Link to="/projects">
            <li className={classes['container__pages__items']}>Projects</li>
          </Link>

          <Link to="/terms-and-conditions">
            <li className={classes['container__pages__items']}>
              Terms and Conditions
            </li>
          </Link>
          <Link to="/privacy-policy">
            <li className={classes['container__pages__items']}>
              Privacy Policy
            </li>
          </Link>
          <Link to="/impressum">
            <li className={classes['container__pages__items']}>
              Impressum
            </li>
          </Link>
        </div>
        <div className={classes['container__copyright']}>
          <p>Alexander Loran © {year}</p>
        </div>
      </div>
    </section>
  );
};

export default FooterSection;
