import React, { useRef, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import classes from './LandingThree.module.scss';
import AsciiScene from './AsciiScene';

const LandingThree = (props, ref) => {
  const containerRef = useRef();
  const titleRef = useRef();
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const titleAnimation = gsap.to(titleRef.current, {
      top: '50%',
      opacity: 1,
    });

    const containerTrigger = ScrollTrigger.create({
      trigger: containerRef.current,
      start: 'top top',
      scrub: true,
      animation: titleAnimation,
    });

    return () => {
      containerTrigger.kill();
    };
  }, []);

  return (
    <div ref={containerRef} style={{ height: '100%' }}>
      <h1 ref={titleRef} className={classes['titleText']}>
        Who am I?
      </h1>
      <Canvas className={classes.canvas}>
        <OrbitControls enableRotate={false} enableZoom={false} />
        <AsciiScene
          animationProgress={props.animationProgress}
          afterRotateProgress={props.afterRotateProgress}
        />
      </Canvas>
      <div className={classes.testest}></div>
    </div>
  );
};

export default LandingThree;
