import React from 'react';
import classes from './CurrentMainOccupation.module.scss';

const CurrentMainOccupation = () => {
  return (
    <div className={classes['container']}>
      <div className={classes['container__grid']}>
        <div className={classes['container__grid__element']}>
          <div className={classes['container__grid__element__title']}>
            <h1>Current Main Occupation</h1>
          </div>
          <div className={classes['container__grid__element__box']}>
            <h2>Softwaredeveloper at Simplic GmbH</h2>
            <p style={{marginBottom: '1em'}}>With over 2 years of experience</p>
            <h2>Studying economics in University</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentMainOccupation;
