import React, { useRef, useContext } from 'react';
import { MainContext } from '../../contexts/MainContext';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import LinkButton from './LinkButton';

import classes from './LinkSectionElement.module.scss';

const styling = {
  circle: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    color: 'black',
    boxShadow: '20px 20px 60px #bebebe, -20px -20px 60px #ffffff;',
    border: '0.15em solid rgba(255,255,255,0.4)',
    transition: '0.4s ease',
  },
};

const hoverAnimation = (text) => {
  text.current.style.transition = '0.4s ease';
  text.current.style.color = 'var(--lightestBlue-color)';
};

const endHoverAnimation = (text) => {
  text.current.style.transition = '0.4s ease';
  text.current.style.color = 'rgba(0,0,0,0.4)';
};

const LinkSectionElement = (props) => {
  const linkTextRef = new useRef();

  const { landingPageViewed } = useContext(MainContext);

  //#region Animaitons
  let rotate = 0;
  let scale = 1;
  let opacity = 1;

  if (!landingPageViewed) {
    rotate = -180;
    scale = 0;
    opacity = 0;
  }

  const circle = {
    hidden: {
      rotate: rotate,
      scale: scale,
    },
    visible: {
      rotate: 0,
      scale: 1,
    },
  };

  const text = {
    hidden: {
      opacity: opacity,
    },
    visible: {
      opacity: 1,
    },
  };
  //endregion Animations

  const Icon = props.icon;

  return (
    <motion.div
      className={classes['link__circle']}
      onHoverStart={() => {
        hoverAnimation(linkTextRef);
      }}
      onHoverEnd={() => {
        endHoverAnimation(linkTextRef);
      }}
      key={props.animationKey}
    >
      <motion.div variants={circle}>
        <Link to={props.link}>
          <LinkButton>
            <Icon />
          </LinkButton>
        </Link>
      </motion.div>
      <motion.div variants={text} className={classes['link__circle__text']}>
        <p className={classes['link__circle__text__content']} ref={linkTextRef}>
          {props.text}
        </p>
      </motion.div>
    </motion.div>
  );
};

LinkSectionElement.defaultProps = {
  text: '',
};

LinkSectionElement.propTypes = {
  icon: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  animationKey: PropTypes.string,
  text: PropTypes.string,
};

export default LinkSectionElement;
