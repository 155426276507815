import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import classes from './Underline.module.scss';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Underline = (props) => {
  gsap.registerPlugin(ScrollTrigger);

  const strokeRef = useRef();

  const styles = {
    text: {
      fontFamily: `var(${props.fontFamily})`,
      fontWeight: 'bold',
      fontSize: props.fontSize,
      color: props.fontColor,
    },
    mark: {
      bottom: props.markPosition,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }
  }

  useEffect(() => {
    strokeRef.current.style.strokeDasharray = parseInt(
      strokeRef.current.getTotalLength()
    );

    strokeRef.current.style.strokeDashoffset = parseInt(
      strokeRef.current.getTotalLength()
    );

    strokeRef.current.style.strokeWidth = 0;

    const underlineAnimation = gsap
      .timeline()
      .to(strokeRef.current, {
        strokeWidth: 130,
        duration: 0.1,
      })

      .to(strokeRef.current, {
        strokeDashoffset: 0,
        duration: 1,
      });

    const underlineTrigger = ScrollTrigger.create({
      trigger: strokeRef.current,
      animation: underlineAnimation,
      start: 'center 85%',
      refreshPriority: -1,
    });

    return () => {
      underlineTrigger.kill();
    };
  }, []);

  return (
    <em style={styles.text} className={classes['container']}>
      {props.children}
      <svg
        className={classes['container__underline']}
        style={styles.mark}
        viewBox="0 0 841.995 595.35"
        width="100%"
        preserveAspectRatio="none"
      >
        <defs />
        <g id="Layer-1">
          <clipPath id="underline-clip-path">
            <path d="M66.8054 280.894C140.391 262.947 402.637 257.18 806.319 270.197C838.764 271.893 845.684 297.617 798.313 293.486C379.03 262.706 152.441 293.584 102.895 304.25C53.349 314.916 78.6876 328.852 51.3371 331.793C35.4241 333.504 6.92077 330.647 9.97349 313.139C12.3134 299.719 28.2612 290.155 66.8054 280.894Z" />
          </clipPath>

          <path
            ref={strokeRef}
            className={classes['container__underline__fill']}
            d="M63.623 318.143C75.5778 301.59 244.535 289.935 588.368 276.143C644.848 273.877 710.35 276.532 780.151 281.709"
          />
        </g>
      </svg>
    </em>
  );
};

Underline.defaultProps = {
  fontFamily: '--font-title',
  fontSize: '1em',
  fontColor: 'black',
  markPosition: '-60%'
};

Underline.propTypes = {
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  fontColor: PropTypes.string,
  markPosition: PropTypes.string,
};

export default Underline;
