import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import classes from './Typewriter.module.scss';

const Typewriter = (props) => {
  const textRef = useRef();
  const containerRef = useRef();

  const [typed, setTyped] = useState('');

  useEffect(() => {
    const nextTyped = props.text.slice(0, typed.length + 1);

    if (nextTyped === typed) return;

    const timeout = setTimeout(() => {
      setTyped(props.text.slice(0, typed.length + 1));
    }, 100);

    return () => clearTimeout(timeout);
  }, [typed]);

  useEffect(() => {
    textRef.current.style.color = props.textColor;
    textRef.current.style.fontFamily = props.fontFamily;
  }, []);

  return (
    <motion.div
      ref={containerRef}
      className={classes['typewriter__container']}
     
      initial={{ scale: 1 }}
      animate={{
        scale: 0.25,
        transition: { delay: 2, duration: 0.5, ease: 'easeInOut' },
      }}
    >
      <h1 className={classes['typewriter__text']} ref={textRef}>
        {typed}
        <span className={classes['typewriter__blinker']}>|</span>
      </h1>
    </motion.div>
  );
};

Typewriter.defaultProps = {
  text: "Hi, I'm Alex",
  textColor: 'var(--secondaryBlue-color)',
  fontFamily: 'var(--font-base)',
};

Typewriter.propTypes = {
  text: PropTypes.string,
  textColor: PropTypes.string,
  fontFamily: PropTypes.string,
}

export default Typewriter;
