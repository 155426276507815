import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import './index.css';
import App from './App';
//import { Resume } from './routes';
import { MyProjects, Resume, ContactMe } from './routes';
import { PrivacyPolicy } from './routes/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './routes/TermsConditions/TermsConditions';
import MainContextProvider from './contexts/MainContext';
import Impressum from './routes/Impressum/Impressum';

const root = ReactDOM.createRoot(document.getElementById('root'));

//ScrollTrigger Settings for mobile
gsap.registerPlugin(ScrollTrigger);
if (matchMedia('(hover: none)').matches) {
  //if device touch
  // removed resize we trigger it manually
  ScrollTrigger.config({
    autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load',
  });
  let w = window.innerWidth + window.outerWidth;
  window.addEventListener('resize', () => {
    if (w !== window.innerWidth + window.outerWidth) {
      ScrollTrigger.refresh();
      w = window.innerWidth + window.outerWidth;
    }
  });
}

const element = (
  <MainContextProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="resume" element={<Resume />} />
        <Route path="projects" element={<MyProjects />} />
        <Route path="contact" element={<ContactMe />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-and-conditions" element={<TermsConditions />} />
        <Route path="impressum" element={<Impressum />} />
      </Routes>
    </BrowserRouter>
  </MainContextProvider>
);

root.render(element);
