import React from 'react';

import { HoneycombGrid } from '../../../components';
import JavaScriptHoneycombElement from './JavaScriptHoneycombElement/JavaScriptHoneycombElement';
import FullstackHoneycombElement from './FullStackHoneycombElement/FullstackHoneycombElement';
import CSharpHoneycombElement from './CSharpHoneycombElement/CSharpHoneycombElement';
import JavaHoneycombElement from './JavaHoneycombElement/JavaHoneycombElement';
import ReactHoneycombElement from './ReactHoneycombElement/ReactHoneycombElement';

const MainDevHoneycombGrid = (props) => {
  return (
    <HoneycombGrid>
      <JavaScriptHoneycombElement onFixPosition={props.onFixPosition} id="1" />
      <FullstackHoneycombElement onFixPosition={props.onFixPosition} id="2" />
      <CSharpHoneycombElement onFixPosition={props.onFixPosition} id="3" />
      <JavaHoneycombElement onFixPosition={props.onFixPosition} id="4" />
      <ReactHoneycombElement onFixPosition={props.onFixPosition} id="5" />
    </HoneycombGrid>
  );
};

export default MainDevHoneycombGrid;
