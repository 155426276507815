import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import classes from './BundestagSection.module.scss';
import { GradientTextAnimated } from '../../components';
import { images } from '../../constants';

const BundestagSection = () => {
  gsap.registerPlugin(ScrollTrigger);

  const contentRef = useRef();
  const sectionRef = useRef();
  const imageRef = useRef();

  useEffect(() => {
    const masterTl = gsap.timeline();

    const scrollAnimation = gsap.timeline();
    scrollAnimation
      .to(contentRef.current, { duration: 2 })
      .to(sectionRef.current, {
        duration: 2,
        backgroundColor: 'black',
        color: 'white',
      })
      .to(contentRef.current, { duration: 4 });

    const imageAnimation = gsap.timeline();
    imageAnimation.to(imageRef.current, { duration: 1, opacity: 1 });
    imageAnimation.to(imageRef.current, { duration: 2 });

    masterTl.add(scrollAnimation, 0);
    masterTl.add(imageAnimation, 4);

    const contentPin = ScrollTrigger.create({
      animation: masterTl,
      trigger: contentRef.current,
      start: 'top top',
      end: '+=100%',
      scrub: 1,
      pin: true,
    });

    return () => {
      contentPin.kill();
    };
  }, []);

  return (
    <section ref={sectionRef} className={classes['container']}>
      <div ref={contentRef} className={classes['container__content']}>
        <img
          ref={imageRef}
          className={classes['container__content__image']}
          src={images.meBundestag}
          alt="Alexander Loran in the German Bundestag"
        />
        <div className={classes['container__content__text']}>
          <div style={{ width: '100%' }}>in the </div>
          <div className={classes['container__content__text--gradient']}>
            <GradientTextAnimated fontFamily="--font-title" text="Bundestag" />
          </div>
          <p className={classes['container__content__text--detailed']}>
            Stepping into the heart of German politics, I immersed myself in the
            complexities of german national politics. Throughout my time in the
            Bundestag, I gained valuable insights with a specific focus on the
            economic and energy political challenges that shape Germany's
            future.
          </p>
        </div>
      </div>
    </section>
  );
};

export default BundestagSection;

/**
 * <TextWrapper
            text="Stepping into the heart of German politics, I immersed myself in the complexities of economic and energy policy challenges. Throughout my time in the Bundestag, I gained valuable insights with a specific focus on the economic and energy political challenges that shape Germany's future."
            textLength={58}
            fontSize="0.3em"
            maxWidth="calc(min(25em, 50vw))"
            fontColor="white"
            buttonColor="white"
          />
 */
