import React from 'react';
import PropTypes from 'prop-types';

import classes from './FullscreenCard.module.scss';

const FullscreenCard = (props) => {
  const style = {
    container: {
      backgroundColor: props.backgroundColor,
    },
  };

  return (
    <div style={style.container} className={classes['card']}>
      <div className={classes['tools']}>
        <div className={classes['circle']}>
          <span className={[classes['red'], classes['box']].join(' ')}></span>
        </div>
        <div className={classes['circle']}>
          <span
            className={[classes['yellow'], classes['box']].join(' ')}
          ></span>
        </div>
        <div className={classes['circle']}>
          <span className={[classes['green'], classes['box']].join(' ')}></span>
        </div>
      </div>
      <div className={classes['card__content']}>{props.children}</div>
    </div>
  );
};

FullscreenCard.defaultProps = {
  backgroundColor: '#011522',
};

FullscreenCard.propTypes = {
  backgroundColor: PropTypes.string,
};

export default FullscreenCard;
