import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import classes from './ZoomImageContainer.module.scss';

const ZoomImageContainer = (props) => {
  const styles = {
    background: {
      backgroundColor: 'black',
    },
    animatedBackground: {
      backgroundImage: `url(${props.containerImage}`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',            
    },
  };

  gsap.registerPlugin(ScrollTrigger);

  const animatedBackgroundRef = useRef();

  useEffect(() => {
    const backgroundAnimation = gsap.to(animatedBackgroundRef.current, {
      top: 0,
      left: 0,
      right: 0,
      borderRadius: '0',
    });

    const animatedBackgroundTrigger = ScrollTrigger.create({
      trigger: animatedBackgroundRef.current,
      start: 'top 80%',
      end: 'top 65%',
      scrub: true,
      animation: backgroundAnimation,
    });

    return () => {
      animatedBackgroundTrigger.kill();
    };
  }, []);

  return (
    <div className={classes['background']} style={styles.background}>
      <div className={classes['container']}>
        <div
          style={styles.animatedBackground}
          ref={animatedBackgroundRef}
          className={classes['animatedBackground']}
        />
        {props.children}
      </div>
      
    </div>
  );
};

ZoomImageContainer.propTypes = {
  containerImage: PropTypes.any.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default ZoomImageContainer;
