import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classes from './TextWrapper.module.scss';

const TextWrapper = (props) => {
  const styles = {
    button: {
      color: props.buttonColor,
    },
    text: {
      fontSize: props.fontSize,
      fontFamily: `var(${props.fontFamily})`,
      color: props.fontColor,
      maxWidth: props.maxWidth,
    },
  };

  const [showMore, setShowMore] = useState(false);

  return (
    <div className={classes['container']} style={styles.text}>
      <p>
        {showMore ? props.text : `${props.text.substring(0, props.textLength)}`}{' '}
        <button style={styles.button} className={classes['container__button']} onClick={() => setShowMore(!showMore)}>
          {showMore ? 'show less' : '... show more'}
        </button>
      </p>
    </div>
  );
};

TextWrapper.defaultProps = {
  fontSize: '1em',
  fontFamily: '--font-base',
  fontColor: 'black',
  buttonColor: 'black',
  textLength: 30,
  maxWidth: '12em'
};

TextWrapper.propTypes = {
  text: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  fontFamily: PropTypes.string,
  fontColor: PropTypes.string,
  buttonColor: PropTypes.string,
  textLength: PropTypes.number,
  maxWidth: PropTypes.string
};

export default TextWrapper;
