import React from 'react';
import PropTypes from 'prop-types';

import { DiJavascript1 } from 'react-icons/di';
import LoremIpsum from 'react-lorem-ipsum';

import classes from './JavaScriptHoneycombElement.module.scss';
import HoneycombElement from '../../../../components/HoneycombGrid/HoneycombElement';
import { GradientText } from '../../../../components';

const JavaScriptIcon = () => <DiJavascript1 className={classes['icon']} />;

const JavaScriptHoneycombElement = (props) => {
  const gradientOne = '#FFDF00';
  const gradientTwo = '#ffaa00';

  return (
    <HoneycombElement
      id={props.id}
      title="JavaScript"
      category="Language"
      icon={JavaScriptIcon}
      onFixPosition={props.onFixPosition}
      iconSizeAdapter="10%"
      titleColorFirst={gradientOne}
      titleColorSecond={gradientTwo}
    >
      <div className={classes['container']}>
        <h1 style={{ marginTop: '1em' }}>
          A{' '}
          <GradientText
            text="strong"
            textFirstColor={gradientOne}
            textSecondColor={gradientTwo}
          />{' '}
          language for a strong beginnig.
        </h1>
        <p>
          Beeing my first programming language, JavaScript holds a special place
          in my heart.
        </p>
        <h2>Projects</h2>
        <p>
          My most ambitious project to date, developed using JavaScript, is{' '}
          <em>
            <GradientText
              text="this website"
              textFirstColor="#ffd000"
              textSecondColor={gradientTwo}
            />
          </em>
          .
        </p>
        <h2>Coding Competitions</h2>
        <p>
          Furthermore, JavaScript has become my go-to language for coding
          competitions such as Google KickStart.
        </p>
      </div>
    </HoneycombElement>
  );
};

JavaScriptHoneycombElement.propTypes = {
  id: PropTypes.string.isRequired,
  onFixPosition: PropTypes.func.isRequired,
};

export default JavaScriptHoneycombElement;
