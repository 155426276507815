import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import classes from './HoneycombOpened.module.scss';
import GradientText from '../GradientText/GradientText';

const HoneycombOpened = (props) => {
  const Icon = props.icon;

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2, delay: 0.1 }}
        style={{ pointerEvents: 'auto' }}
        className={classes['overlay']}
      />
      <motion.div className={classes['card']}>
        <motion.div
          className={classes['card__content']}
          layoutId={`card-container-${props.id}`}
        >
          <motion.div className={classes['card__top']}>
            <motion.div
              className={classes['card__top__title']}
              layoutId={`title-container-${props.id}`}
            >
              <span className={classes['card__top__category']}>
                {props.category}
              </span>
              <h1>
                <GradientText text={props.title} fontSize='2.5em' textFirstColor={props.titleColorFirst} textSecondColor={props.titleColorSecond}/>
              </h1>
            </motion.div>
            <motion.div
              className={classes['card__top__icon']}
              layoutId={`icon-container-${props.id}`}
            >
              <Icon />
            </motion.div>
          </motion.div>

          <motion.div
            className={classes['card__content__container__text']}
            animate
          >
            {props.children}
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  );
};

HoneycombOpened.defaultProps = {
  iconSizeAdapter: '20%',
  category: '',
  titleColorFirst: '#48cae4',
  titleColorSecond: '#bc48ff',
};

HoneycombOpened.propTypes = {
  iconSizeAdapter: PropTypes.string,
  category: PropTypes.string,
  icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  titleColorFirst: PropTypes.string,
  titleColorSecond: PropTypes.string,
};

export default HoneycombOpened;
