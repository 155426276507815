import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import classes from './DesignSection.module.scss';
import { images } from '../../constants';
import { ZoomContainer } from '../../container';
import { Mark, SmallCard } from '../../components';

const DesignSection = () => {
  gsap.registerPlugin(ScrollTrigger);

  const contentRef = useRef();
  const cardSectionRef = useRef();

  const cardOneRef = useRef();
  const cardTwoRef = useRef();
  const cardThreeRef = useRef();
  const cardFourRef = useRef();

  useEffect(() => {
    const masterTl = gsap.timeline();

    function cardAnimation(ref) {
      return gsap
        .timeline()
        .to(ref.current, {
          duration: 1,
          opacity: 1,
        })
        .to(ref.current.children[1], {
          duration: 1,
          transform: 'translateY(0%)',
          opacity: 1,
        })
        .to(ref.current.children[1], {
          duration: 1,
        });
    }

    const revealCards = gsap.timeline().to(cardSectionRef.current, {
      duration: 1,
      opacity: 1,
    });

    masterTl.add(revealCards, 0);
    masterTl.add(cardAnimation(cardOneRef), 0);
    masterTl.add(cardAnimation(cardTwoRef), 1);
    masterTl.add(cardAnimation(cardThreeRef), 2);
    masterTl.add(cardAnimation(cardFourRef), 3);

    const designTrigger = ScrollTrigger.create({
      animation: masterTl,
      trigger: contentRef.current,
      start: 'center center',
      end: '+=200%',
      scrub: 1,
      pin: true,
      refreshPriority: -1,
    });
    return () => {
      designTrigger.kill();
    };
  }, []);

  return (
    <div className={classes['container']}>
      <ZoomContainer containerColor="white" backgroundColor="black">
        <div ref={contentRef} className={classes['container__content']}>
          <div className={classes['container__content__text']}>
            <p className={classes['container__content__text--upper']}>
              Crafting
            </p>
            <Mark fontSize="1em">Awesomeness</Mark>
            <p className={classes['container__content__text--subtext']}>
              Striking visuals are my passion
            </p>
          </div>
          <div
            ref={cardSectionRef}
            className={classes['container__content__programms']}
          >
            <h1>Experience</h1>
            <div className={classes['container__content__programms__cards']}>
                <SmallCard
                  ref={cardOneRef}
                  opacity={0}
                  image={images.photoshop}
                  imageAlt='Photoshop'
                  subText="+5 years"
                  subTextColor="#31A8FF"
                />
                <SmallCard
                  ref={cardTwoRef}
                  opacity={0}
                  image={images.premierePro}
                  imageAlt='Premiere Pro'
                  subText="+5 years"
                  subTextColor="#9999FF"
                />
                <SmallCard
                  ref={cardThreeRef}
                  opacity={0}
                  image={images.afterEffects}
                  imageAlt='After Effects'
                  subText="+3 years"
                  subTextColor="#9999FF"
                />
                <SmallCard
                  ref={cardFourRef}
                  opacity={0}
                  image={images.illustrator}
                  imageAlt='Illustrator'
                  subText="+1 year"
                  subTextColor="#ff9a00"
                />
            </div>
          </div>
          <img
            className={classes['container__content__background']}
            src={images.designSectionBackground}
            alt="background"
          />
        </div>
      </ZoomContainer>
    </div>
  );
};

export default DesignSection;
