import React from 'react';
import PropTypes from 'prop-types';

import classes from './CSharpHoneycombElement.module.scss';
import { images } from '../../../../constants';
import HoneycombElement from '../../../../components/HoneycombGrid/HoneycombElement';
import { GradientText } from '../../../../components';

const CSharpIcon = () => (
  <img src={images.csharp} className={classes['icon']} alt="c# icon" />
);

const CSharpHoneycombElement = (props) => {
  const gradientOne = '#8900f2';
  const gradientTwo = '#d100d1';

  const Gradient = (text) => (
    <GradientText
      text={text}
      textFirstColor={gradientOne}
      textSecondColor={gradientTwo}
    />
  );

  return (
    <HoneycombElement
      id={props.id}
      title="C# Dev"
      category="Language"
      icon={CSharpIcon}
      onFixPosition={props.onFixPosition}
      iconSizeAdapter="8%"
      titleColorFirst={gradientOne}
      titleColorSecond={gradientTwo}
    >
      <div className={classes['container']}>
        <h1 style={{ marginTop: '1em' }}>
          Over {Gradient('2 years')} of {Gradient('experience')}.
        </h1>
        <p>
          I work at Simplic GmbH as a .Net developer for over 2 years.
          <h2 style={{ marginTop: '2em' }}>Experienced</h2> in{' '}
          <GradientText
            text="desktop"
            textFirstColor="#ffd000"
            textSecondColor={gradientTwo}
          />{' '}
          and{' '}
          <GradientText
            text="API"
            textFirstColor="#ffd000"
            textSecondColor={gradientTwo}
          />{' '}
          development. <h2>Developing</h2>Developing software for logistic
          management, ERP, DMS and various other applications.
          <h2>Tasks</h2>
          <p>
            <ul>
              <li>Softwaredevelopment</li>
              <li>Softwarearchitecture</li>
            </ul>
          </p>
          <h2>More information</h2> at{' '}
          <span
            style={{ zIndex: 10 }}
            onClick={() => {
              window.open('https://simplic.biz/', '_blank').focus();
            }}
          >
            <a
              href="https://simplic.biz/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Simplic GmbH
            </a>
            .
          </span>
        </p>
      </div>
    </HoneycombElement>
  );
};

CSharpHoneycombElement.propTypes = {
  id: PropTypes.string.isRequired,
  onFixPosition: PropTypes.func.isRequired,
};

export default CSharpHoneycombElement;
