import React, { createContext, useState } from 'react';
export const MainContext = createContext();

const MainContextProvider = (props) => {
  const [landingPageViewed, setLandingPageViewed] = useState(false);
  const Url = 'http://localhost:3000';

  return (
    <MainContext.Provider value={{ landingPageViewed, setLandingPageViewed, Url }}>
      {props.children}
    </MainContext.Provider>
  );
};

export default MainContextProvider;
