import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import classes from './GradientTextAnimated.module.scss';

const GradientTextAnimated = (props) => {
  gsap.registerPlugin(ScrollTrigger);

  const textRef = useRef();
  let style;

  if (props.clip) {
    style = {
      text: {
        background: `-webkit-linear-gradient(58deg, ${props.textFirstColor} 0%, ${props.textSecondColor} 100%)`,
        background: `linear-gradient(58deg, ${props.textFirstColor} 0%, ${props.textSecondColor} 100%)`,
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        backgroundSize: '200%',
        fontSize: props.fontSize,
        fontFamily: `var(${props.fontFamily})`,
      },
    };
  } else {
    style = {
      text: {
        background: `-webkit-linear-gradient(58deg, ${props.textFirstColor} 0%, ${props.textSecondColor} 100%)`,
        background: `linear-gradient(58deg, ${props.textFirstColor} 0%, ${props.textSecondColor} 100%)`,
        backgroundSize: '200%',
        fontSize: props.fontSize,
        fontFamily: `var(${props.fontFamily})`,
      },
    };
  }

  useEffect(() => {
    const textAnimation = gsap.to(textRef.current, {
      scrollTrigger: {
        trigger: textRef.current,
        start: '0% center',
        end: '100% center',
        scrub: true,
        refreshPriority: -1,
      },
      backgroundPosition: '100% 50%',
    });

    return () => {
      textAnimation.kill();
    };
  }, []);

  return (
    <span ref={textRef} style={style.text} className={classes['text']}>
      {props.text}
    </span>
  );
};

GradientTextAnimated.defaultProps = {
  textFirstColor: '#48cae4',
  textSecondColor: '#bc48ff',
  fontFamily: '--font-base',
  fontSize: '1em',
  clip: true,
};

GradientTextAnimated.propTypes = {
  text: PropTypes.string.isRequired,
  textFirstColor: PropTypes.string,
  textSecondColor: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  clip: PropTypes.bool,
};

export default GradientTextAnimated;
