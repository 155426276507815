import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';

import classes from './HoneycombElement.module.scss';
import HoneycombOpened from './HoneycombOpened';
import HoneycombClosed from './HoneycombClosed';

const HoneycombElement = (props) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = (event) => {
    event.preventDefault();

    setOpen(!open);
    props.onFixPosition(open);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      setOpen(false);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setOpen(false);
      });
    };
  }, []);

  return (
    <div className={classes['container']} onClick={toggleOpen}>
      <li>
        <HoneycombClosed id={props.id} icon={props.icon} />
      </li>
      <AnimatePresence>
        {open && (
          <HoneycombOpened
            id={props.id}
            icon={props.icon}
            title={props.title}
            category={props.category}
            iconSizeAdapter={props.iconSizeAdapter}
            titleColorFirst={props.titleColorFirst}
            titleColorSecond={props.titleColorSecond}
          >
            {props.children}
          </HoneycombOpened>
        )}
      </AnimatePresence>
    </div>
  );
};

HoneycombElement.propTypes = {
  iconSizeAdapter: PropTypes.string,
  category: PropTypes.string,
  icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onFixPosition: PropTypes.func.isRequired,
  titleColorFirst: PropTypes.string,
  titleColorSecond: PropTypes.string,
};

export default HoneycombElement;
