import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { images } from '../../constants';
import classes from './EuropeanParliamentSection.module.scss';
import { GradientText, GradientTextAnimated } from '../../components';

const EuropeanParliamentSection = () => {
  gsap.registerPlugin(ScrollTrigger);

  const containerRef = useRef();
  const imageRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    const masterTl = gsap.timeline();

    const scrollAnimation = gsap.timeline();
    scrollAnimation
      .to(contentRef.current, { duration: 2 })
      .to(contentRef.current, {
        duration: 2,
        opacity: 1,
      })
      .to(contentRef.current, { duration: 4 });

    masterTl.add(scrollAnimation, 0);

    const contentPin = ScrollTrigger.create({
      animation: masterTl,
      trigger: containerRef.current,
      start: 'top top',
      end: '+=100%',
      scrub: 1,
      pin: true,
    });

    return () => {
      contentPin.kill();
    };
  }, []);

  return (
    <div className={classes['container']} ref={containerRef}>
      <img
        ref={imageRef}
        className={classes['container__image']}
        src={images.alexEU}
        alt="Alexander Loran in the European Parliament"
      />
      <div className={classes['container__content']} ref={contentRef}>
        <div className={classes['container__content__text']}>
          <div style={{ width: '100%' }}>in the </div>
          <GradientText
            fontFamily="--font-title"
            textFirstColor="#ff9500"
            textSecondColor="#ffee32"
            text="EU Parliament"
            clip={false}
          />
          <div>
            <p className={classes['container__content__text--detailed']}>
              During my visit to the European Parliament, I gained valuable
              insights into international politics, with a focus on
              international trade, economic and monetary affairs, and foreign
              affairs. Witnessing policy negotiations, understanding democratic
              complexities, and participating in meetings as well as committees
              highlighted the importance of democracy, dialogue,
              multilateralism, and diplomacy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EuropeanParliamentSection;
