import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import classes from './DeveloperLandingSection.module.scss';
import { videos } from '../../constants';
import { ZoomContainer } from '../../container';

const DeveloperLandingSection = (props, ref) => {
  const titleRef = useRef();
  const underlineRef = useRef();

  useEffect(() => {
    const underlineTimeLine = gsap.timeline();
    underlineTimeLine
      .to(underlineRef.current, { duration: 0.53, visibility: 'hidden' })
      .to(underlineRef.current, { duration: 0.53, visibility: 'visible' })
      .to(underlineRef.current, { duration: 0.53, visibility: 'hidden' })
      .to(underlineRef.current, { duration: 0.53, visibility: 'visible' })
      .to(underlineRef.current, { duration: 0.53, visibility: 'hidden' })
      .to(underlineRef.current, { duration: 0.53, visibility: 'visible' });

    const blinkTrigger = ScrollTrigger.create({
      animation: underlineTimeLine,
      trigger: underlineRef.current,
      start: 'top 90%',
      refreshPriority: -1,
    });

    return () => {
      blinkTrigger.kill();
    };
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ZoomContainer
        backgroundColor="black"
        containerColor="var(--darkGray-color)"
      >
        <div ref={ref} className={classes['container']}>
          <h1 ref={titleRef} className={classes['container__title']}>
            &lt;A Developer
            <div
              ref={underlineRef}
              className={classes['container__title__box']}
            >
              <video
                className={classes['container__title__box--video']}
                playsInline
                autoPlay
                muted
                loop
              >
                <source src={videos.devGradient} type="video/mp4" />
              </video>
            </div>
            &gt;
          </h1>
        </div>
      </ZoomContainer>
    </div>
  );
};

export default React.forwardRef(DeveloperLandingSection);
