import React, { useEffect, useRef } from 'react';

import classes from './TextRotator.module.scss';

let current = 0;
let previous = 0;

const TextRotator = () => {
  const textRotationRef = new useRef();

  useEffect(() => {
    function rotate() {
      let prev = textRotationRef.current.children[previous];
      previous = current;
      let show = textRotationRef.current.children[current];
      let next = textRotationRef.current.children[current + 1];
      if (next) {
        current++;
      } else {
        next = textRotationRef.current.children[0];
        current = 0;
      }
      prev.removeAttribute('data-up');

      show.removeAttribute('data-show');
      show.setAttribute('data-up', '');

      next.setAttribute('data-show', '');
    }

    const intervalId = setInterval(rotate, 2000);

    return () => {
      clearInterval(intervalId);
    }
  }, []);

  return (
    <div className={classes['container']}>
      I do
      <div className={classes['mask']} ref={textRotationRef}>
        <span data-show>algorithms.</span>
        <span>web dev.</span>
        <span>dotNet.</span>
        <span>backend.</span>
      </div>
    </div>
  );
};

export default TextRotator;
