import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

import classes from './ChatMessenger.module.scss';

const transition = {
  type: 'spring',
  stiffness: 200,
  mass: 0.2,
  damping: 20,
};

const variants = {
  initial: {
    opacity: 0,
    y: 300,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition,
  },
};

const ChatMessenger = (props) => {
  const messageList = props.messages;
  const [messages, setMessages] = useState([]);

  //document.documentElement.style.setProperty('--bg', props.backgroundColor);
  //const [currentMessage, setCurrentMessage] = useState(0);

  useEffect(() => {
    let messageNum = 0;

    const addMessage = () => {
      setMessages((curr) => {
        if (curr.length > props.maxMessageCount) {
          const first = curr.shift(); // eslint-disable-line
        }

        let next = messageList[messageNum];

        return [...curr, next];
      });
      messageNum = messageNum + 1 < messageList.length ? messageNum + 1 : 0;
    };

    let id = setInterval(addMessage, 2000);

    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <AnimatePresence>
      <ol className={classes['list']}>
        {messages.map(({ text, sent }, i) => {
          const isLast = i === messages.length - 1;
          const noTail = !isLast && messages[i + 1]?.sent === sent;
          return (
            <motion.li
              key={text}
              className={[
                classes['shared'],
                sent ? classes['sent'] : classes['received'],
                noTail && classes['noTail'],
              ].join(' ')}
              initial="initial"
              animate="enter"
              variants={variants}
              layout
            >
              {text}
            </motion.li>
          );
        })}
      </ol>
    </AnimatePresence>
  );
};

ChatMessenger.defaultProps = {
  maxMessageCount: 5,
  backgroundColor: 'red',
};

ChatMessenger.propTypes = {
  messages: PropTypes.any.isRequired,
  maxMessageCount: PropTypes.number,
  backgroundColor: PropTypes.string,
}

export default ChatMessenger;
