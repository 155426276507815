import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import classes from './PoliticsSection.module.scss';
import { HoverButton } from '../../components';
import { BsInstagram } from 'react-icons/bs';

import { images } from '../../constants';

const PoliticsSection = () => {
  gsap.registerPlugin(ScrollTrigger);

  const headingRef = useRef();

  useEffect(() => {
    const moveInAnimation = gsap.timeline().to(headingRef.current, {
      opacity: 1,
      transform: 'translateY(0%)',
      duration: 1,
    });

    const underlineTrigger = ScrollTrigger.create({
      trigger: headingRef.current,
      animation: moveInAnimation,
      start: 'center center',
      refreshPriority: -1,
    });

    return () => {
      underlineTrigger.kill();
    };
  }, []);

  return (
    <div className={classes['container']}>
      <div className={classes['container__overlay']}>
        <div className={classes['container__overlay__more']}>
          <HoverButton link="https://www.instagram.com/alexander.loran">
            see more{' '}
            <span className={classes['container__overlay__more--icon']}>
              <BsInstagram />
            </span>
          </HoverButton>
        </div>
      </div>
      <div className={classes['container__images']}>
        <img
          className={classes['container__images__background']}
          src={images.politicsBackground}
          alt=""
        />
        <h1 ref={headingRef} className={classes['container__heading']}>
          Politics
        </h1>

        <img
          className={classes['container__images__foreground']}
          src={images.politicsMe}
          alt=""
        />
      </div>
    </div>
  );
};

export default PoliticsSection;
