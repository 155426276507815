import React from 'react';
import PropTypes from 'prop-types';

import classes from './HoverButton.module.scss';

const HoverButton = (props) => {
  const styles = {
    hoverButton: {
      fontSize: props.fontSize,
    }
  }

  return (
    <a href={props.link} target='_blank' rel="noreferrer noopener">
    <button style={styles.hoverButton} className={classes['hoverbutton']}>
      <span className={classes['hoverbutton__circle']}>
        <span className={classes['hoverbutton__icon']}></span>
      </span>
      <span className={classes['hoverbutton__text']}>{props.children}</span>
    </button>
    </a>
  );
};

HoverButton.defaultProps = {
  fontSize: '5em',
};

HoverButton.propTypes = {
  link: PropTypes.string.isRequired,
  fontSize: PropTypes.string,  
};

export default HoverButton;
