import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { images, animatedImages } from '../../constants';

import classes from './MyProjects.module.scss';
import {
  MusicGameSection,
  BundestagSection,
  ComingMoreSection,
  FooterSection,
  EuropeanParliamentSection,
} from '../../section';

const MyProjects = () => {
  ScrollTrigger.getAll().forEach((t) => t.kill());

  gsap.registerPlugin(ScrollTrigger);

  const titleContainerRef = useRef();
  const titleContainerTestRef = useRef();
  const titleImageContainerRef = useRef();
  const iPadImageContainerRef = useRef();
  const iPadFadeTriggerRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);

    const media = window.matchMedia('(max-aspect-ratio: 2/3)');

    let animateTo = media.matches ? '200%' : '110%';

    const ipadScrubAnimation = gsap.timeline();
    ipadScrubAnimation
      .to(titleContainerTestRef.current, { duration: 4, width: '150vw' })
      .to(iPadImageContainerRef.current, { duration: 4, width: animateTo })
      .to(iPadImageContainerRef.current, { duration: 2, opacity: 0 })
      .to(iPadImageContainerRef.current, { duration: 2, opacity: 0 });

    const ipadTrigger = ScrollTrigger.create({
      animation: ipadScrubAnimation,
      trigger: titleContainerRef.current,
      start: 'top top',
      end: '+=70%',
      scrub: 1,
      pin: true,
    });

    return () => {
      ipadTrigger.kill();
    };
  }, []);

  return (
    <>
      <div className={classes['container']}>
        <div style={{ height: '170vh' }}>
          <div ref={titleContainerRef} className={classes['head']}>
            <div
              ref={titleImageContainerRef}
              className={classes['head__container']}
            >
              <div
                ref={titleContainerTestRef}
                className={classes['head__test']}
              >
                <img
                  className={classes['head__container__image--projects']}
                  src={animatedImages.projects}
                  alt="projects"
                />
                <img
                  ref={iPadImageContainerRef}
                  className={classes['head__container__image--iPadTable']}
                  src={images.ipad}
                  alt="ipad in the center"
                />
              </div>
            </div>
          </div>
        </div>

        <div ref={iPadFadeTriggerRef} style={{ display: 'flex' }}></div>
        <EuropeanParliamentSection />
        <BundestagSection />
        <MusicGameSection />
        <FooterSection />
      </div>
    </>
  );
};

export default MyProjects;
