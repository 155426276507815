import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import classes from './Mark.module.scss';

const Mark = (props) => {
  const styles = {
    text: {
      fontFamily: `var(${props.fontFamily})`,
      fontWeight: 'bold',
      fontSize: props.fontSize,
      color: props.fontColor,
    },
    mark: {
      bottom: props.markPosition,
    }
  }

  gsap.registerPlugin(ScrollTrigger);

  const strokeRef = useRef();

  useEffect(() => {
    strokeRef.current.style.strokeDasharray = strokeRef.current.getTotalLength();
    strokeRef.current.style.strokeWidth= 0;

    strokeRef.current.style.strokeDashoffset = parseInt(
      strokeRef.current.getTotalLength()
    );

    const markAnimation = gsap.timeline()
    .to(strokeRef.current, {
      strokeWidth: 300,
      duration: 0.1
    })
    .to(strokeRef.current, {
      strokeDashoffset: 0,
      duration: 1,
    });

    const underlineTrigger = ScrollTrigger.create({
      trigger: strokeRef.current,
      animation: markAnimation,
      start: 'center 85%',
      refreshPriority: -1,
    });

    return () => {
      underlineTrigger.kill();
    };
  }, []);

  return (
    <em className={classes['container']} style={styles.text}>
      {props.children}
      <svg
        className={classes['container__mark']}
        style={styles.mark}
        viewBox="0 0 841.995 595.35"
        width="100%"
        preserveAspectRatio="none"
      >
        <defs />
        <g id="Layer-1">
          <clipPath id="mark-clip-path">
            <path d="M96.2639 195.092C426.353 211.926 755.186 187.174 755.186 187.174C797.034 183.666 812.605 190.23 813.064 196.133C813.451 201.113 799.09 209.049 799.09 215.1C799.09 221.152 813.561 229.672 813.561 237.124C813.561 244.577 804.405 243.675 804.405 249.993C804.405 256.312 816.191 262.094 816.191 272.196C816.191 282.298 804.501 284.003 804.501 290.024C804.501 292.324 825.094 295.814 819.162 305.385C815.245 311.705 799.389 322.097 809.194 323.314C812.28 323.697 830.172 325.069 824.959 329.48C824.959 329.48 814.838 339.063 815.817 343.795C817.106 350.028 824.509 352.884 825.102 352.996C828.142 353.574 846.985 363.301 795.119 374.365C254.604 477.006 69.6169 415.757 43.409 406.608C31.4174 402.421 13.0993 394.856 12.7524 383.61C12.4054 372.364 24.3631 379.798 24.5027 371.809C24.6423 363.82 13.4067 367.422 13.4067 359.77C13.4067 352.117 22.0588 349.299 22.0491 341.889C22.0394 334.478 11.7651 330.5 11.7651 323.426C11.7651 316.351 23.2705 308.832 23.6015 299.885C23.9324 290.938 12.156 285.815 12.156 278.908C12.156 272.001 24.5418 267.528 24.2371 260.421C23.9324 253.314 13.1816 249.64 13.1816 244.249C13.1816 238.858 27.5076 229.95 27.1398 222.11C26.772 214.271 9.75685 210.746 9.75685 205.726C9.75685 200.707 23.491 191.091 96.2639 195.092Z" />
          </clipPath>

          <path
            ref={strokeRef}
            className={classes['container__mark__fill']}
            d="M24.6418 314.159C24.6418 314.159 529.686 357.884 789.85 280.129"
          />
        </g>
      </svg>
    </em>
  );
};

Mark.defaultProps = {
  fontFamily: '--font-title',
  fontSize: '60px',
  fontColor: 'black',
  markPosition: '-60%'
};

Mark.propTypes = {
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  fontColor: PropTypes.string,
  markPosition: PropTypes.string,
};

export default Mark;
