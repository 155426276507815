import React from 'react';
import PropTypes from 'prop-types';

import classes from './SmallCard.module.scss';

const SmallCard = React.forwardRef((props, ref) => {
  const styles = {
    title: {
      color: props.titleColor,
    },
    subText: {
      color: props.subTextColor,
    },
    card: {
      opacity: props.opacity,
    }
  };

  return (
    <div ref={ref} style={styles.card} className={classes['container']}>
      <div className={classes['container__card']}>
        <img
          className={classes['container__card__image']}
          src={props.image}
          alt={props.imageAlt}
        />
        <div style={styles.title} className={classes['container__card__title']}>
          <p>{props.title}</p>
        </div>
      </div>
      <div style={styles.subText} className={classes['container__subtext']}>
        <p>{props.subText}</p>
      </div>
    </div>
  );
});

SmallCard.defaultProps = {
  title: '',
  titleColor: 'white',
  subText: '',
  subTextColor: 'black',
  opacity: 1,
};

SmallCard.propTypes = {
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  subText: PropTypes.string,
  subTextColor: PropTypes.string,
  opacity: PropTypes.number,
};

SmallCard.displayName = 'SmallCard';

export default SmallCard;
