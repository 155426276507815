import React, { useEffect } from 'react';

import classes from './Resume.module.scss';
import { MediaCursor } from '../../components';
import { Timeline } from '../../container';
import { FooterSection } from '../../section';
import { images } from '../../constants';
import CurrentMainOccupation from '../../container/Timeline/CurrentMainOccupation';

const Resume = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes['container']}>
      <MediaCursor
        selectionColor="white"
        selectionBackgroundColor="black"
        backgroundColor="#f5f5f5"
      >
        <img
          draggable={false}
          className={classes['container__title']}
          src={images.resumeTitle}
          alt="ipad in the center"
        />
      </MediaCursor>

      <div className={classes['container__timeline']}>
        <CurrentMainOccupation/>
        <Timeline />
      </div>
      <FooterSection />
    </div>
  );
};

export default Resume;
