import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import classes from './LegalContainer.module.scss';
import FooterSection from '../../section/FooterSection/FooterSection';

const LegalContainer = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <section className={classes['container']}>
      <div className={classes['container__top']}>
        <div className={classes['container__top__block']}>
          <h3 className={classes['container__top__block__topic']}>Legal</h3>
          <h1 className={classes['container__top__block__title']}>
            {props.legalType}
          </h1>
          <p className={classes['container__top__block__subtext']}>
            Last updated on{' '}
            <em className={classes['emphasis']}>{props.updateDate}</em>
          </p>
        </div>
      </div>
      <div className={classes['container__content']}>
        <div className={classes['container__content__style']}>
          {props.children}
        </div>
      </div>
      <FooterSection />
    </section>
  );
};

LegalContainer.propTypes = {
  legalType: PropTypes.string.isRequired,
  updateDate: PropTypes.string.isRequired,
};

export default LegalContainer;
