import React from 'react';
import PropTypes from 'prop-types';

import classes from './TimelineDefaultTemplate.module.scss';
import { IoLocationSharp } from 'react-icons/io5';

const TimelineDefaultTemplate = (props) => {
  return (
    <div className={classes['container']}>
      <div className={classes['container__content']}>
        <div className={classes['container__content__title']}>
          {props.location !== undefined && (
            <>
              <div className={classes['container__content__title--location']}>
                <IoLocationSharp
                  className={
                    classes['container__content__title--location--icon']
                  }
                />
                {props.location}
              </div>
            </>
          )}
          <div className={classes['container__content__title--text']}>
            {props.title}
            
          </div>
        </div>
        <div className={classes['container__content__details']}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

TimelineDefaultTemplate.defaultProps = {};

TimelineDefaultTemplate.propTypes = {
  title: PropTypes.any.isRequired,
  location: PropTypes.string,
};

export default TimelineDefaultTemplate;
