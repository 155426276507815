import React, { useState } from 'react';

import classes from './HoneycombGrid.module.scss';
import HoneycombElement from './HoneycombElement';

const HoneycombGrid = (props) => {

  return (
    <ul className={classes['container']}>
      {props.children}
      {/*<HoneycombElement onFixPosition={props.onFixPosition} Id="1"/>
      <HoneycombElement Id="2"/>
      <HoneycombElement Id="3"/>
      <HoneycombElement Id="4"/>*/}
    </ul>
  );
};

export default HoneycombGrid;

/* <div style={{ width: '100%', height: '300px' }}>
      <HoneycombOpened />
      <ul className={classes['honeycomb']}>
        <li className={classes['honeycomb__cell']} onClick={toggleOpen}>
        
          <HoneycombClosed />
        </li>

        <li className={classes['honeycomb__cell']}>
          <div className={classes['honeycomb__cell__img']}>Testsdr</div>
          <div className={classes['honeycomb__cell__title']}>Test2</div>
        </li>
        <li className={classes['honeycomb__cell']}>
          <div className={classes['honeycomb__cell__img']}>Testsdr</div>
          <div className={classes['honeycomb__cell__title']}>Test3</div>
        </li>
        <li className={classes['honeycomb__cell']}>
          <div className={classes['honeycomb__cell__img']}>Testsdr</div>
          <div className={classes['honeycomb__cell__title']}>Test3</div>
        </li>
        <li className={classes['honeycomb__cell']}>
          <div className={classes['honeycomb__cell__img']}>Testsdr</div>
          <div className={classes['honeycomb__cell__title']}>Test3</div>
        </li>
        <li
          className={[
            classes['honeycomb__cell'],
            classes['honeycomb__placeholder'],
          ].join(' ')}
        >
          <div className={classes['honeycomb__cell__img']}>Testsdr</div>
          <div className={classes['honeycomb__cell__title']}>Tes2t3</div>
        </li>
      </ul>
      <svg
        style={{ visibility: 'hidden', position: 'absolute' }}
        width="0"
        height="0"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="8" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="goo"
            />
            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
          </filter>
        </defs>
      </svg>
    </div>*/
